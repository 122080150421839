export const ExperienceArray = [
	{
		dateStart: '1/2019',
		dateEnd: 'Present',
		title: 'Front-end developer',
		company: 'Freelance',
		keywords: [
			'HTML5',
			'CSS3/SCSS',
			'Javascript',
			'React.js',
			'Sketch',
			'inVision',
			'WordPress',
			'Google Maps',
			'Webflow',
			'Typeform',
		],
		details: [
			'Design, build and maintain responsive WordPress sites and widgets with HTML, CSS and Javascript',
			'Prototype with visual builder such as Webflow and Elementor for WordPress',
		],
	},
	{
		dateStart: '11/2016',
		dateEnd: '12/2018',
		title: 'Design Technologist',
		company: 'Cota Healthcare',
		keywords: [
			'Angular.js',
			'Ember.js',
			'Handlebars.js',
			'Qlik Sense',
			'HTML5',
			'CSS3/SCSS',
			'Sketch',
			'inVision',
		],
		details: [
			'Create and maintain design system',
			'UX/UI design, code presentation layers, prototype',
			'Healthcare information management platforms',
		],
	},
	{
		dateStart: '9/2016',
		dateEnd: '11/2016',
		title: 'Contract Frontend Developer',
		company: 'National Geographic',
		keywords: ['Handlebars.js', 'HTML5', 'CSS3/SCSS', 'JavaScript', 'RWD'],
		details: [
			'Working closely with PM, UI/UX designers and editors I created an aesthetically pleasing responsive and cross-devise webpage',
			'Project name: Best Trips 2017',
		],
	},
	{
		dateStart: '6/2015',
		dateEnd: '4/2016',
		title: 'Design Technologist / Prototyper',
		company: 'E*Trade',
		keywords: [
			'Angular.js',
			'Harp.js',
			'HTML5',
			'CSS3/SCSS',
			'Sketch',
			'Responsive Web Design',
		],
		details: [
			'Create and maintain design system',
			// tslint:disable-next-line:max-line-length
			'Code high fidelity prototype as well as production ready responsive website from sketch wireframe using HTML5, CSS3, Javascript',
			'Test cross ­browser and device compatibility in Chrome, Firefox, Safari IE 8,9,10, 11, iPhone and android',
		],
	},
	{
		dateStart: '4/2012',
		dateEnd: '6/2015',
		title: 'Design Technologist',
		company: 'New York Magazine',
		keywords: [
			'HTML5',
			'CSS3/SCSS',
			'Photoshop',
			'Illustrator',
			'Dreamweaver',
			'Optimizely',
			'Wordpress',
			'Responsive Web Design',
		],
		details: [
			'Brands: ​New York Magazine, Vulture, The Cut, Bedford + Bowery, Science of Us',
			'Create components from Photoshop wireframes using HTML, CSS',
		],
	},
];

export const keywordsArray = [
	'HTML5',
	'CSS3/SCSS',
	'Javascript',
	'React.js',
	'Storybook',
	'Styled-Components',
	'Photoshop',
	'Illustrator',
	'XD',
	'Premiere Pro',
	'Figma',
	'Sketch',
	'Responsive Web Design',
];
